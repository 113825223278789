import React, { useCallback, useEffect, useState } from 'react';
import Form from '../../../../../components/forms/form';
import '../style.scss';
import { Button, Tooltip } from '@syneto/compass-react';
import Utils from '../../../../../helpers/Utils';

export const ModalBody = (props) => {
	const { versions, uploadedImage, setUploadedImage, setExistingImageUrl, change, editedAddon } = props;
	const [tiers, setTiers] = useState(editedAddon?.addonTiers ? editedAddon.addonTiers : []);
	const [versionOptions, setVersionOptions] = useState([]);
	const [endingVersionOptions, setEndingVersionOptions] = useState([]);
	const [startingVersion, setStartingVersion] = useState('');
	const [endingVersion, setEndingVersion] = useState('');

	const startingVersionChanged = (e) => {
		setStartingVersion(e);
		let temp = [...versions];
		while (temp.length > 0 && temp[0] !== e) {
			temp.shift();
		}
		setEndingVersionOptions([{ label: 'None', value: null }, ...renderVersionsOptions(temp)]);
		setEndingVersion(temp[0]);
	};

	const endingVersionChanged = (e) => {
		setEndingVersion(e);
	};

	const addTier = () => {
		setTiers([...tiers, { name: '', code: '', value: null, uuid: Utils.uuidv4() }]);
	};

	const handleTierChange = (index, e) => {
		const { value } = e.target;
		const name = e.target.name.match(/\.(.*)/)[1];
		const newTiers = [...tiers];
		newTiers[index][name] = value;
		setTiers(newTiers);
	};

	const removeTier = (index) => {
		const newTiers = tiers.filter((_, i) => { return i !== index; });
		setTiers(newTiers);
	};

	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onloadend = () => {
			setUploadedImage(reader.result);
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleRemoveImage = () => {
		setUploadedImage('');
		setExistingImageUrl('');
	};

	useEffect(() => {
		setTiers(editedAddon && editedAddon.addonTiers ? editedAddon.addonTiers : []);
	}, [editedAddon]);

	useEffect(() => {
		setVersionOptions(renderVersionsOptions(versions));
		setEndingVersionOptions([{ label: 'None', value: null }, ...renderVersionsOptions(versions)]);
	}, [versions]);

	useEffect(() => {
		change('addonTiers', tiers);
	}, [tiers, change]);

	const maxPeriodYearsOptions = [
		{ value: 1, label: '1 year' },
		{ value: 2, label: '2 years' },
		{ value: 3, label: '3 years' },
		{ value: 4, label: '4 years' },
		{ value: 5, label: '5 years' },
		{ value: 6, label: '6 years' },
		{ value: 7, label: '7 years' },
		{ value: 8, label: '8 years' },
		{ value: 9, label: '9 years' },
		{ value: 10, label: '10 years' }
	];

	const maxTrialDurationDaysOptions = [
		{ value: 0, label: 'None' },
		{ value: 30, label: '30 days' },
		{ value: 60, label: '60 days' },
		{ value: 90, label: '90 days' },
		{ value: 120, label: '120 days' },
		{ value: 150, label: '150 days' },
		{ value: 180, label: '180 days' }
	];

	const renderVersionsOptions = (versions) => {
		return versions ? versions.map((version) => {
			return { value: version, label: version };
		}) : [{ value: '4', label: '4' }];
	};

	return (
		<fieldset className="border-0">
			<div className="row">
				<Form.text name="name" label="Add-on name" placeholder="Name" required autocomplete="off" />
			</div>
			<div className="row">
				<Form.text name="summary" label="Summary" placeholder="Short description" required autocomplete="off" />
			</div>
			<div className="row">
				<div className="col-8">
					<Form.textarea
						name="description"
						label="Description"
						placeholder="Long description"
						style={{ height: '120px' }}
						required
					/>
				</div>
				<div className="col-4">
					<label className="form-label">Thumbnail</label>
					{!uploadedImage && (
						<>
							<label className="img-upload-btn btn-link" htmlFor="fileUpload"><i className="fas fa-upload"></i><span>Upload image</span></label>
							<input
								type="file"
								id="fileUpload"
								name="fileUpload"
								accept="image/*"
								onChange={handleFileUpload}
							/>
						</>
					)}
					{uploadedImage && (
						<div className="image-container">
							<img src={uploadedImage} alt="Uploaded" />
							<Button className="remove-image-btn" type="button" appearance="danger" role="tertiary" onClick={handleRemoveImage}>
								Remove image
							</Button>
						</div>
					)}
				</div>
			</div>
			<div className="row">
				<Form.text
					name="presentationVideoUrl"
					label="Youtube link"
					placeholder="https://www.youtube.com/watch?v=T2lp3kaBkcQ"
					autocomplete="off"
				/>
			</div>
			<div className="row">
				<div className="col-3">
					<Form.select
						label="Add-on period"
						name="maxPeriodYears"
						options={maxPeriodYearsOptions}
						emptyOption="Please select"
						required
					/>
				</div>
				<div className="col-3">
					<Form.select
						label="Trial duration"
						name="maxTrialDurationDays"
						options={maxTrialDurationDaysOptions}
						emptyOption="Please select"
						required
					/>
				</div>
			</div>
			<h4 className="mt-4">Tiers</h4>
			{tiers.length ? (
				<div>
					{tiers.map((tier, index) => {
						return (
							<div key={tier.uuid ?? tier.id} className="row">
								<div className="col-3">
									<Form.text
										name={`addonTiers[${index}].name`}
										label="Tier name"
										placeholder="Tier name"
										onChange={(e) => { return handleTierChange(index, e); }}
										autocomplete="off"
										required
									/>
								</div>
								<div className="col-3">
									<Form.text
										name={`addonTiers[${index}].code`}
										label="Tier code"
										placeholder="Tier code"
										onChange={(e) => { return handleTierChange(index, e); }}
										autocomplete="off"
										required
									/>
								</div>
								<div className="col-3">
									<Form.text
										type="number"
										min="0"
										name={`addonTiers[${index}].value`}
										label="Tier value"
										placeholder="Tier value"
										onChange={(e) => { return handleTierChange(index, e); }}
										autocomplete="off"
										required
									/>
								</div>
								<div className="delete-tier-btn col-3 d-flex align-items-center">
									<Button type="button" appearance="danger" role="tertiary" onClick={() => { return removeTier(index); }}>
										Delete
									</Button>
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<h6 className="no-tiers-defined">No tiers defined</h6>
			)}
			<Button className="m-0 mb-3" type="button" role="tertiary" onClick={addTier}>
				Add new tier
			</Button>

			<div className="row">
				<div className="col-3">
					<Form.text name="code" label="Feature ID (code name)" placeholder="Code name" autocomplete="off" required />
				</div>
			</div>

			<h4 className="mt-4">
				Supported product versions
				<Tooltip text="Supporting product versions should start with the first version that includes the add-on and end with the last version that includes the add-on." placement="top" trigger="hover">
					<i className="fas fa-info-circle"></i>
				</Tooltip>
			</h4>
			<div className="row">
				<div className="col-3">
					<Form.select
						label="Starting"
						name="minProductVersion"
						options={versionOptions}
						emptyOption="Please select"
						onChange={startingVersionChanged}
						required
					/>
				</div>
				<div className="col-3">
					<Form.select
						label="Ending"
						name="maxProductVersion"
						value={{ value: endingVersion, label: endingVersion }}
						options={endingVersionOptions.length ? endingVersionOptions : [{ label: 'None', value: null }, ...versionOptions]}
						emptyOption="Please select"
						onChange={endingVersionChanged}
					/>
				</div>
			</div>
		</fieldset>
	);
};
