class AddonValidationService {
	static validate = (values) => {
		const errors = {};
		const fields = [
			'name',
			'summary',
			'description',
			'maxPeriodYears',
			'code',
			'minProductVersion',
		];

		fields.forEach((field) => {
			if (AddonValidationService.validateRequired(values[field])) {
				errors[field] = 'Cannot be empty';
			}
		});

		if (values.addonTiers && values.addonTiers.length > 0) {
			const tiersArrayErrors = [];
			values.addonTiers.forEach((tier, index) => {
				const tierErrors = {};
				if (AddonValidationService.validateRequired(tier.name)) {
					tierErrors.name = 'Cannot be empty';
				}
				if (AddonValidationService.validateRequired(tier.code)) {
					tierErrors.code = 'Cannot be empty';
				}
				if (AddonValidationService.validateRequired(tier.value)) {
					tierErrors.value = 'Cannot be empty';
				} else if (isNaN(tier.value) || tier.value < 0) {
					tierErrors.value = 'Must be a positive number';
				}
				if (Object.keys(tierErrors).length > 0) {
					tiersArrayErrors[index] = tierErrors;
				}
			});
			if (tiersArrayErrors.length > 0) {
				errors.addonTiers = tiersArrayErrors;
			}
		}

		return errors;
	};

	static validateRequired(value) {
		return !value || value.length === 0;
	}
}

export default AddonValidationService;
